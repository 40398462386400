<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_stockInquiryDetailByClient" />
      <!-- 検索項目 -->
      <v-form ref="form">
        <v-container fluid>
          <!-- １行目スタート -->
          <v-row class="search-row">
            <!-- 取引先 -->
            <div class="search-autocomplete-supplier first-search-item" style="width: 500px">
              <!-- 取引先-->
              <v-text-field
                dense
                id="get-supplier"
                v-model="suppliersSelectedName"
                :label="$t('label.lbl_supplier')"
                class="txt-single"
                persistent-hint
                :hint="suppliersSelectedName"
                readonly
              ></v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>
            <div class="search-autocomplete-product">
              <!-- 品番 -->
              <v-text-field
                readonly
                dense
                v-model="productCd"
                :label="$t('label.lbl_productCode')"
                persistent-hint
                :hint="productCd"
                class="txt-single"
              ></v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>
            <div class="search-autocomplete-product">
              <!-- 付帯品番 -->
              <v-text-field
                readonly
                dense
                v-model="productSubCd"
                :label="$t('label.lbl_productAccessoryCd')"
                persistent-hint
                :hint="productSubCd"
                class="txt-single"
              ></v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>
            <div>
              <!-- 在庫 -->
              <v-text-field
                readonly
                dense
                v-model="strageDiv"
                :label="$t('label.lbl_strageQty')"
                class="txt-single"
              ></v-text-field>
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <!--ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 戻るボタン -->
                <v-btn color="primary" id="get-search" class="api-btn" @click="closePage">{{
                  $t("btn.btn_back")
                }}</v-btn>
              </div>
            </div>
          </v-row>

          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalCount }}件</span>
              </div>
            </div>
          </v-row>
          <!-- 2行目ここまで -->
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputTableList"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          group-by="groupByNo"
          height="600px"
        >
          <!-- ロットNoヘッダー -->
          <template v-slot:[`header.lotSerialEtc`]>
            {{ $t("label.lbl_lot") + "／" + $t("label.lbl_serial") }}<br />
            <!-- {{
              $t("label.lbl_dueDate") +
              "／" +
              $t("label.lbl_lotSubkey1") +
              "／" +
              $t("label.lbl_lotSubkey2")
            }} -->
          </template>
          <template v-slot:[`group`]="{ group, items }">
            <tr>
              <td style="vertical-align: top">
                {{ items[0].lot }} / {{ items[0].serial }} <br />
                <!-- {{ items[0].dueDate }} / {{ items[0].accessoryKey1 }} /
                  {{ items[0].accessoryKey2 }} -->
              </td>

              <td style="padding: 0px; vertical-align: top">
                <template v-for="(groupingData, key) in items">
                  <div v-for="(item, index) in groupingData.officeSumList" :key="`a-1-${index}`">
                    <p
                      v-if="index == 0 && groupingData.officeSumList.length > 1 && key == 0"
                      align="center"
                      class="font-size-char"
                    >
                      {{ groupingData.arrivalStatus }}
                    </p>
                    <p
                      v-else-if="index == 0 && groupingData.officeSumList.length > 1 && key != 0"
                      class="border_top_char"
                      align="center"
                    >
                      {{ groupingData.arrivalStatus }}
                    </p>
                    <p
                      v-else-if="index == 0 && groupingData.officeSumList.length == 1 && key != 0"
                      class="border_top_char"
                      align="center"
                    >
                      {{ groupingData.arrivalStatus }}
                    </p>
                    <p
                      v-else-if="index == 0 && groupingData.officeSumList.length == 1 && key == 0"
                      align="center"
                      class="font-size-char"
                    >
                      {{ groupingData.arrivalStatus }}
                    </p>
                    <p v-else>&nbsp;</p>
                  </div>
                </template>
              </td>

              <td style="padding: 0px; vertical-align: top">
                <template v-for="(groupingData, key) in items">
                  <div :key="`b-1-${key}`">
                    <div v-for="(item, index) in groupingData.officeSumList" :key="`b-2-${index}`">
                      <p
                        v-if="index == 0 && groupingData.officeSumList.length > 1 && key == 0"
                        class="font-size"
                      >
                        {{ groupingData.totalSum }}
                      </p>
                      <p
                        v-else-if="index == 0 && groupingData.officeSumList.length > 1 && key != 0"
                        class="border_top_num"
                        align="center"
                      >
                        {{ groupingData.totalSum }}
                      </p>
                      <p
                        v-else-if="index == 0 && groupingData.officeSumList.length == 1 && key == 0"
                        class="font-size"
                      >
                        {{ groupingData.totalSum }}
                      </p>
                      <p
                        v-else-if="index == 0 && groupingData.officeSumList.length == 1 && key != 0"
                        class="border_top_num"
                        align="center"
                      >
                        {{ groupingData.totalSum }}
                      </p>
                      <p v-else>&nbsp;</p>
                    </div>
                  </div>
                </template>
              </td>

              <td style="padding: 0px">
                <template v-for="(groupingData, grIndex) in items">
                  <div :key="`c-1-${grIndex}`">
                    <div v-for="(item, index) in groupingData.officeSumList" :key="`c-2-${index}`">
                      <p
                        v-if="index == 0 && grIndex != 0 && groupingData.officeSumList.length > 1"
                        align="left"
                        class="border_top_saleoffice"
                      >
                        {{ item.salesOffice }}
                      </p>
                      <p
                        v-else-if="
                          index == 0 && grIndex != 0 && groupingData.officeSumList.length == 1
                        "
                        align="left"
                        class="border_top_saleoffice"
                      >
                        {{ item.salesOffice }}
                      </p>
                      <p
                        v-else
                        align="left"
                        class="not_border_top_saleoffice"
                        style="vertical-align: top"
                      >
                        {{ item.salesOffice }}
                      </p>
                    </div>
                  </div>
                </template>
              </td>
              <td style="padding: 0px">
                <template v-for="(groupingData, grIndex) in items">
                  <div :key="`d-1-${grIndex}`">
                    <div v-for="(item, index) in groupingData.officeSumList" :key="`d-2-${index}`">
                      <p
                        v-if="index == 0 && grIndex != 0 && groupingData.officeSumList.length > 1"
                        align="right"
                        class="border_top"
                      >
                        {{ item.quantity }}
                      </p>
                      <p
                        v-else-if="
                          index == 0 && grIndex != 0 && groupingData.officeSumList.length == 1
                        "
                        align="right"
                        class="border_top"
                      >
                        {{ item.quantity }}
                      </p>
                      <p v-else align="right" style="vertical-align: top">
                        {{ item.quantity }}
                      </p>
                    </div>
                  </div>
                </template>
              </td>
            </tr>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="Math.ceil(groupCountList.length / itemsPerPage)"
            :total-visible="7"
          ></v-pagination>
        </div>
      </v-container>
      <!-- ナビゲーション -->
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />
      <!-- 戻るボタンを押下時出現ダイアログ -->
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
            <v-btn @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { i18n } from "../../lang/lang.js";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import NavBar from "../../components/NavBar.vue";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import SimpleDialog from "@/components/SimpleDialog";
import ConfirmDialog from "@/components/ConfirmDialog";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";

export default {
  name: appConfig.SCREEN_ID.P_CLT_002,
  components: {
    Loading,
    sideMenu,
    NavBar,
    SimpleDialog,
    ConfirmDialog,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: true,
    },
    // 確認メッセージ
    confirmDialog: {
      isOpen: false,
      message: "",
      redMessage: "",
      okAction: () => {},
    },
    rules: {
      dataFormatRules: (value) => !!value || i18n.tc("check.chk_outputFormatDiv"),
      outPutPatternRules: (value) => !!value || i18n.tc("check.chk_outputPattern"),
    },
    // 検索時点の日時
    year: "",
    today: "",
    nowTime: "",
    search: "",
    // ページング
    page: 1,
    pageCount: 0,
    totalCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    updateDialogMessage: false,
    // 選択された取引先
    suppliersSelected: [],
    groupCountList: [],
    // 営業所ごとの数量リスト
    officeSumList: [],
    // 品番
    productCd: "",
    // 付帯品番
    productSubCd: "",
    // 戻るダイアログのメッセージ
    backMessage: "",
    // メニュー
    openMenu: null,
    // 初期データ
    defaultData: [],
    // 前画面からの検索条件
    searchParam: [],
    strageDiv: "",
    // ヘッダ
    headerItems: [
      // ロット/ S/N / 期限日 / 付属キー1 / 付属キー2
      {
        value: "lotSerialEtc",
        width: "15%",
        align: "left",
        sortable: false,
        class: "headerItem",
      },
      // 品質区分
      {
        text: i18n.tc("label.lbl_arrivalStatus"),
        value: "arrivalStatus",
        width: "6%",
        align: "left",
        sortable: false,
      },

      // 合計
      {
        text: i18n.tc("label.lbl_totalSum"),
        value: "totalSum",
        width: "6%",
        align: "right",
        sortable: false,
        class: "total-sum-header",
      },
      // 営業所
      {
        text: i18n.tc("label.lbl_salesOffice"),
        value: "salesOffice",
        width: "10%",
        align: "center",
        sortable: false,
      },
      // 数量
      {
        text: i18n.tc("label.lbl_quantity"),
        value: "quantity",
        width: "7%",
        align: "right",
        sortable: false,
      },
    ],
    inputList: [],
    selectItemInfo: [],
    // 検索結果
    stockList: [],
    // 取引先プルダウン(前画面情報保存)
    supplierList: [],
    // 入荷ステータスプルダウン(前画面情報保存)
    qualityDivList: [],
    // 在庫有無プルダウン(前画面情報保存)
    inStockList: [],
    // 営業所リスト(前画面情報保存)
    officeList: [],
    // ユーザーメーカ区分リスト(前画面情報保存)
    userMakerList: [],
    nameList: [],
    suppliersSelectedName: "",
    lot: "",
    qualityDiv: "",
    itemName: "",
    itemStandardSid: "",
  }),
  methods: {
    // 初期化
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      // 検索条件
      this.searchParam = this.$route.params.stockInquiryList.headerList;
      // 取引先
      this.suppliersSelectedName = this.$route.params.stockInquiryList.suppliersSelectedName;
      this.suppliersSelected = this.$route.params.stockInquiryList.suppliersSelected;
      // 品番
      this.productCd = this.$route.params.stockInquiryList.item.itemCd;
      // 付帯品番
      this.productSubCd = this.$route.params.stockInquiryList.item.accessoryCd;
      // 在庫有無
      this.strageDiv = this.$route.params.stockInquiryList.strageDiv;
      // 前画面で選択した行情報
      this.selectItemInfo = this.$route.params.stockInquiryList.item;
      this.supplierList = this.$route.params.stockInquiryList.supplierList;
      this.officeList = this.$route.params.stockInquiryList.officeList;
      // 品質区分リスト
      this.qualityDivList = this.$route.params.stockInquiryList.qualityDivList;
      // ユーザーメーカー区分リスト
      this.userMakerList = this.$route.params.stockInquiryList.userMakerList;
      // 在庫有無区分リスト
      this.inStockList = this.$route.params.stockInquiryList.inStockList;
      // 品番リスト
      this.nameList = this.$route.params.stockInquiryList.nameList;
      // 詳細情報取得
      this.getStockDetailData();
    },
    /**
     * 在庫一覧詳細情報取得
     */
    getStockDetailData() {
      var now = new Date();
      this.today = dateTimeHelper.toStringDate("YYYY/MM/DD", now);
      this.nowTime = dateTimeHelper.toStringDate("hh:mm", now);
      // 現在時刻を表示

      const config = this.$httpClient.createGetApiRequestConfig();

      // ローディング画面表示ON
      this.loadingCounter = 1;

      // 取引先
      config.params.clientSid = this.searchParam.searchSuppliersSelected;

      // 営業所SID
      if (this.searchParam.searchOfficeSelected) {
        config.params.officeSid = this.searchParam.searchOfficeSelected;
      }

      // 商品標準SID
      config.params.itemStandardSid = this.selectItemInfo.itemStandardSid;

      // 品番
      config.params.itemCd = this.productCd.padEnd(50, " ") + this.productSubCd.padEnd(50, " ");

      // 在庫有無
      if (this.searchParam.searchInstockDiv) {
        config.params.inStock = this.searchParam.searchInstockDiv;
      }

      // 取引先CD
      if (this.searchParam.searchClientCd) {
        config.params.clientCd = this.searchParam.searchClientCd;
      }
      // console.debug("apigetStock() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_STOCK_BY_CLIENT_DTL, config)
          .then((response) => {
            // console.debug("apigetStock() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];

              this.stockList = jsonData.resIdv.stocksByClientDetail;

              for (let i = 0; i < this.stockList.length; i++) {
                const officeSumList = [];
                this.officeSumList = this.stockList[i].officeSumList;
                this.officeSumList.forEach((v) =>
                  officeSumList.push({
                    officeSid: v.officeSid,
                    salesOffice: v.salesOffice,
                    quantity: commonUtil.formatToCurrency(Number(v.quantity)),
                  })
                );
                list.push({
                  lot:
                    this.trimLot(this.stockList[i].lotNo, 0, 20) != ""
                      ? this.trimLot(this.stockList[i].lotNo, 0, 20)
                      : "-", // ロット
                  serial:
                    this.trimLot(this.stockList[i].lotNo, 20, 100) != ""
                      ? this.trimLot(this.stockList[i].lotNo, 20, 100)
                      : "-", // シリアル
                  // serial:
                  //   this.trimLot(this.stockList[i].lotNo, 20, 52) != ""
                  //     ? this.trimLot(this.stockList[i].lotNo, 20, 52)
                  //     : "-", // シリアル
                  // dueDate:
                  //   this.trimLot(this.stockList[i].lotNo, 52, 60) != ""
                  //     ? this.trimLot(this.stockList[i].lotNo, 52, 60)
                  //     : "-", // 期限日
                  // accessoryKey1:
                  //   this.trimLot(this.stockList[i].lotNo, 60, 80) != ""
                  //     ? this.stockList[i].lotNo.substring(60, 80).trim()
                  //     : "-", // 付属キー1
                  // accessoryKey2:
                  //   this.trimLot(this.stockList[i].lotNo, 80, 100) != ""
                  //     ? this.trimLot(this.stockList[i].lotNo, 80, 100)
                  //     : "-", // 付属キー2
                  arrivalStatus: this.stockList[i].qualityDiv, // 品質区分
                  totalSum: commonUtil.formatToCurrency(Number(this.stockList[i].totalByQuality)), // 品質区分ごとの数量
                  officeSumList: officeSumList, // 営業所ごとの数量リスト
                  groupNo: this.stockList[i].lotNo, // グルーピングNo
                });
              }
              this.inputList = list;
              this.groupCountList = [...new Set(this.inputList.map((data) => data.groupNo))];
              this.totalCount = this.groupCountList.length;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject(resolve);
            }
          })
          .catch((resolve) => {
            console.error("apigetStock() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * ロットNoのトリム処理
     */
    trimLot(val, str, end) {
      return val.substring(str, end).trim();
    },
    // 初期データ取得
    getInitData() {
      const qualityList = getParameter.getCodeMst(appConfig.CODETYPE.IN_QUALITY_DIV);
      Promise.all([qualityList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.qualityDivList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * ボタン押下時の処理
     */
    viewConfirmDialog() {
      let message;
      let action = () => {};

      message = messsageUtil.getMessage("P-COM-001_004_W");
      action = this.updateOk;

      this.confirmDialog.message = message;
      this.confirmDialog.title = appConfig.DIALOG.title;
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = action;
    },

    /**
     * 戻るボタン押下
     */
    closePage() {
      this.updateDialogMessage = true;

      this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
    },

    // 戻るボタンダイアログ：OK押下
    updateOk() {
      this.updateDialogMessage = false;

      // 取引先営業所別在庫一覧に戻る場合
      this.$router.push({
        name: appConfig.SCREEN_ID.P_CLT_001,

        params: {
          searchParam: this.searchParam,
          suppliersSelected: this.suppliersSelected,
          supplierList: this.supplierList,
          officeList: this.officeList,
          // 品質区分リスト
          qualityDivList: this.qualityDivList,
          // ユーザーメーカー区分リスト
          userMakerList: this.userMakerList,
          // 在庫有無区分リスト
          inStockList: this.inStockList,
          // 品番リスト
          nameList: this.nameList,
        },
      });
    },

    // 戻るボタンダイアログ：NG押下
    updateNg() {
      this.updateDialogMessage = false;
    },

    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // クリアフラグ
      this.clearFlg = true;
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
  },
  computed: {
    inputTableList: function () {
      return this.inputList.map((item) => {
        let groupByNo;
        groupByNo = item.groupNo;
        const inputItem = {
          groupByNo: groupByNo,
          ...item,
        };
        return inputItem;
      });
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    page: function (newValue) {
      this.page = newValue;
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        //初期画面読み込み時
        if (this.initial == 0) {
          this.sortItem = "";
          this.initial = 1;
        }
        //画面クリア時の場合
        else if (this.clearFlg) {
          this.clearFlg = false;
          return;
        }
        //ソートが解除された場合
        else if (items.sortBy.length < 1) {
          this.sortItem = "";
          this.apigetStock("");
        }
        //ソート実施時
        else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_SRG_001_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.apigetStock("");
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.text-overflow {
  // v-tooltip
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.toatlNum {
  margin-left: 10px;
}

.nowTime {
  font-weight: bold;
  font-size: large;
}

.strageQty {
  font-size: medium;
}

.border {
  border-bottom: 1px solid black;
  padding: 5px;
}

.border_top {
  border-top: 1px solid gray;
}

.border_top_saleoffice {
  border-top: 1px solid gray;
  padding-left: 170px;
}

.not_border_top_saleoffice {
  padding-left: 170px;
}

.border_top_quality {
  border-top: 1px solid gray;
  padding: 0px 0px 110px 0px;
  font-size: 26px;
}

.border_top_sum {
  border-top: 1px solid gray;
  padding: 0px 0px 110px 0px;
  font-size: 26px;
}
.font-size ::v-deep {
  font-size: 26px;
  text-align: right;
}
.font-size-char ::v-deep {
  font-size: 26px;
  text-align: left;
}
.border_top_char {
  border-top: 1px solid gray;
  font-size: 26px;
  text-align: left;
}
.border_top_num {
  border-top: 1px solid gray;
  font-size: 26px;
  text-align: right;
}
.padd-item {
  padding-bottom: 80%;
}

.padd-item-one {
  padding-bottom: 50px;
}

.padd-item-oneOffice {
  padding-bottom: 230px;
}

.not-border {
  padding: 5px;
}

.not-border-ofiiceOne {
  padding-bottom: 120px;
}

.not-border-quality {
  padding-bottom: 110px;
  font-size: 26px;
}

.not-border-sum {
  padding-bottom: 110px;
  font-size: 26px;
}

.not-border-quality-one {
  padding-bottom: 75px;
  padding-left: 10px;
  font-size: 26px;
}

.not-border-sum-one {
  padding-bottom: 75px;
  padding-left: 10px;
  font-size: 26px;
}

.table-data-align-right {
  text-align: right;
}

.headerItem {
  padding-top: 20px;
}

.search-autocomplete-product {
  width: 30rem;
}

::v-deep .v-dialog #detailsDialig .v-input__slot {
  width: 150%;
}

#listData.v-data-table ::v-deep th.total-sum-header {
  padding-right: 0px;
  padding-left: 20px;
  margin-right: 10px;
}

//選択項目枠変更用
// .search-autocomplete-supplierList {
//   width: 21rem;
// }
</style>
